import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Loader from "../Components/loader/Loader";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <Component {...props} />
    </Suspense>
  );
};

function AppRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { element: <Home replace />, index: true },
        { path: "myprofile", element: <MyProfile /> },
        { path: "categories", element: <Categories /> },
        // { path: "orderhistory", element: <OrderHistory /> },
        { path: "about", element: <About /> },
        { path: "gallery", element: <Gallery /> },
        { path: "gallerydetail/:id", element: <GalleryDetail /> },
        { path: "services", element: <Services /> },
        { path: "servicedetail/:id", element: <ServiceDetail /> },
        { path: "blog", element: <Blog /> },
        { path: "blogdetail/:id", element: <BlogDetail /> },
        { path: "faq", element: <Faq /> },
        // { path: "services/:users", element: <ServiceDetail /> },
        { path: "support", element: <Support /> },
        { path: "events", element: <Events /> },
        { path: "bespokeservice", element: <BespokeServices /> },
        { path: "businesssolution", element: <BusinessSolution /> },
        { path: "b2bservice", element: <B2BServices /> },
        { path: "event-list", element: <EventList /> },
        {
          path: "logout",
          element: <Logout />,
        },
      ],
    },
  ]);

  return routes;
}

export default function RouterComponent() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

// components
const Layout = Loadable(lazy(() => import("../Components/Layout/Layout")));
const Home = Loadable(lazy(() => import("../Components/Home/Home")));
const MyProfile = Loadable(
  lazy(() => import("../Components/profile/MyProfile"))
);
const Categories = Loadable(
  lazy(() => import("../Components/Company/Categories"))
);
const OrderHistory = Loadable(
  lazy(() => import("../Components/profile/OrderHistory"))
);
const About = Loadable(lazy(() => import("../Components/Company/About")));
const Gallery = Loadable(lazy(() => import("../Components/Company/Gallery")));
const GalleryDetail = Loadable(
  lazy(() => import("../Components/Company/GalleryDetail"))
);
const Blog = Loadable(lazy(() => import("../Components/Company/Blog")));
const BlogDetail = Loadable(
  lazy(() => import("../Components/Company/BlogDetail"))
);
const Faq = Loadable(lazy(() => import("../Components/Company/Faq")));
const Services = Loadable(lazy(() => import("../Components/Company/Services")));
const ServiceDetail = Loadable(
  lazy(() => import("../Components/Company/ServiceDetail"))
);
const Support = Loadable(lazy(() => import("../Components/Company/Support")));
const Events = Loadable(lazy(() => import("../Components/Events/EventTabs")));
const BespokeServices = Loadable(
  lazy(() => import("../Components/bespokeServices/BespokeServices"))
);
const EventList = Loadable(lazy(() => import("../Components/Home/EventList")));
const BusinessSolution = Loadable(
  lazy(() => import("../Components/businessSolution/BusinessSolution"))
);
const B2BServices = Loadable(
  lazy(() => import("../Components/b2bSevices/B2BServices"))
);
const Logout = Loadable(lazy(() => import("../Components/profile/Logout")));
